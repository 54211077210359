<template>
  <div>
    <div class="is-hidden-desktop">
      <router-view />
    </div>
    <section class="section is-main-section is-hidden-touch">
      <div class="notification is-info">
        <div>
          <b-icon icon="tablet-cellphone" custom-size="default" />
          <span> スマホ・タブレットでご利用ください。 </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  beforeRouteEnter (ro, from, next) {
    document.documentElement.classList.add('is-outer-root')
    next()
  },
  beforeRouteLeave (ro, from, next) {
    document.documentElement.classList.remove('is-outer-root')
    next()
  },
}
</script>

<style>
</style>